/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/06/2021.
 */
import EventsStrategy from "./EventsStrategy";
import { RRule } from "rrule";
import { cleanRRule } from "../../helpers/calendar";
import { getLinksFromDescription, linkify } from "../../helpers/widgetItems";

const sanitizeValue = (value, defaultValue) => {
  return value && !Array.isArray(value) ? value : defaultValue;
};

/**
 *
 * @param Number startDate
 * @param String recurrenceDesc
 * @returns {string|null}
 */
const parseRecurrenceSeries = (startDate, recurrenceDesc) => {
  try {
    const occurrencePattern = /ending after [0-9]+ occurrences/;
    const noOfOccurrences = recurrenceDesc.match(occurrencePattern);

    const endingOnDatePattern = /ending on .*/;
    const hasEndDate = recurrenceDesc.match(endingOnDatePattern);
    let sanitized = recurrenceDesc;

    if (noOfOccurrences && noOfOccurrences.length) {
      sanitized = sanitized.replace(occurrencePattern, "");
      const occurrencesCount = noOfOccurrences[0].match(/[0-9]+/)[0] * 1;
      sanitized = sanitized.replace(
        /from/,
        `for ${occurrencesCount - 1} times from`
      );
    } else if (hasEndDate && hasEndDate.length) {
      const endDate = hasEndDate[0].replace(/ending on /, "");

      sanitized = sanitized.replace(/from.*/, `until ${endDate}`);
    }

    const options = RRule.parseText(sanitized);

    options.dtstart = new Date(startDate * 1000);
    const rule = cleanRRule(options);

    if (rule) {
      return [rule.toString(), rule.toText()];
    }
  } catch (e) {
    console.error(e);
  }
  return [null, null];
};

class PCOEventsStrategy extends EventsStrategy {
  processData(data) {
    return data.map((i) => {
      const attributes = i.data.attributes;
      const eventProfile = i.event_profile.data.attributes;

      const eventTimes = i.data.event_times || null;
      let filteredEventTimes = eventTimes;
      let startTime = i.start_date; /*moment(i.start_date * 1000)
        //.add(utcOffset, "m")
        .unix();*/
      let endTime = i.end_date; /*moment(i.end_date * 1000)
        //.add(utcOffset, "m")
        .unix();*/
      /*const recurrenceDescription =
        attributes.recurrence && attributes.recurrence !== "None"
          ? `${attributes.recurrence}, ${attributes.recurrence_description}`
          : null;

      console.log(recurrenceDescription);*/
      let [recurrence_series, recurrence_description] = [null, null];

      try {
        if (attributes.recurrence && attributes.recurrence !== "None") {
          [recurrence_series, recurrence_description] = parseRecurrenceSeries(
            startTime,
            attributes.recurrence_description
          );
        }
      } catch (e) {
        console.error("Could not parse recurrence series: ", e);
      }

      // consider event times when setting the start and end time

      filteredEventTimes = eventTimes
        ? eventTimes
            // sort events from the earliest to latest
            .sort((a, b) =>
              new Date(a.starts_at).getTime() > new Date(b.starts_at).getTime()
                ? 1
                : 0
            )
            // filter event times by user settings
            .filter((i) => i.visible_on_widget_and_ical)
        : [];

      if (filteredEventTimes.length) {
        startTime = Number.parseInt(
          new Date(filteredEventTimes[0].starts_at).getTime() / 1000
        ) /*+
          utcOffset * 60*/;
        endTime = Number.parseInt(
          new Date(
            filteredEventTimes[filteredEventTimes.length - 1].ends_at
          ).getTime() / 1000
        ) /*+
          utcOffset * 60*/;
      }

      const description =
        eventProfile.description || eventProfile.summary || "";

      let descriptionLinks = null;
      try {
        descriptionLinks = getLinksFromDescription(
          linkify(description.replace("</", "\n</"))
        );
      } catch (e) {
        console.error(e);
      }

      const form = eventProfile.registration_url
        ? {
            id: new Date() * 1,
            url: eventProfile.registration_url,
          }
        : null;

      const ctas = {
        linkFirst: descriptionLinks[0] || null,
        linkLast: descriptionLinks[1] || null,
        eventPage: {
          url: attributes.church_center_url,
          label: "Learn more",
        },
        form: form ? { url: form.url, label: "Registration URL" } : null,
        organizer: null,
      };

      return {
        created: new Date(attributes.created_at).getUnixTime(), //moment(attributes.created_at).unix(),
        modified: new Date(attributes.created_at).getUnixTime(), //moment(attributes.updated_at).unix(),
        //created_by: eventProfile.creator["$"],
        start_time: startTime,
        end_time: endTime,
        //timezone: eventProfile.timezone, todo
        timezone: "UTC",
        recurrence_description: recurrence_description,
        recurrence_series,
        //duration: data.event_duration,
        allDay: attributes.all_day_event || false,
        name: eventProfile.name,
        description,
        summary: eventProfile.summary || "",
        id: i.id,
        ics_id: i.platform_event_id,
        //modified_by: eventProfile.modifier["$"],
        integration_event_id: i.platform_event_id,
        slug: i.short_slug,
        display_location: attributes.location,
        cover_image: sanitizeValue(eventProfile.image_url, null),
        is_private: eventProfile.visible_in_church_center !== true,
        location: attributes.location
          ? {
              city: "",
              /*  line_1: sanitizeValue(eventProfile.location.line_1, null),
            line_2: sanitizeValue(eventProfile.location.line_2, null),*/
              name: attributes.location,
              /*zip: sanitizeValue(eventProfile.location.zip, null),
          street_address: sanitizeValue(
            eventProfile.location.street_address,
            null
          ),*/
            }
          : null,
        organizer: {
          //  id: "",
          name: "", //sanitizeValue(data.leader_name["$"], null),
          // email: sanitizeValue(data.leader_email),
          // phone: sanitizeValue(data.leader_phone, null),
          // notes: sanitizeValue(eventProfile.leader_notes, null),
        },
        contact: {
          phone: "",
        },
        accentColor:
          i.event_profile.tags && i.event_profile.tags.length
            ? i.event_profile.tags[0].data.attributes.color
            : null,
        forms: form,
        eventTimes: filteredEventTimes,
        ctas,
        is_featured: i.is_featured,
      };
    });
  }
}

export default PCOEventsStrategy;
